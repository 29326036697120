<template>
  <div class="flex items-center flex-wrap">
    <div
        v-for="(ip, i) in list_ips.split(',')"
        :class="[$theme.navActiveBg]"
        class="px-2 py-1 rounded-full text-xs mb-1 mr-1"
        :key="i"
    >
      {{ ip }}
    </div>
    <button
        class="copy-button px-2 py-1 rounded-full text-xs mb-1 mr-1"
        :class="[$theme.activeTabBg, $theme.navActiveTextColor2]"
        @click="copyToClipboard"
    >
      {{ $t('copy') }}
    </button>
    <input
        type="text"
        ref="ipAddressInput"
        :value="list_ips"
        readonly
        style="position: absolute; left: -9999px;"
    >
  </div>
</template>

<script>
export default {
  props: ['list_ips', 'exchange_type'],

  methods: {
    copyToClipboard() {
      let copyText = this.$refs.ipAddressInput;
      let copiedText;

      if (this.exchange_type === 'whitebit') {
        copiedText = this.list_ips.replace(/,/g, ';');
      } else {
        copiedText = this.list_ips;
      }

      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(copiedText)
            .then(() => {
              alert(this.$t('copied_ip_address') + ' ' + copiedText);
            })
            .catch(err => {
              console.error('Ошибка копирования: ', err);
            });
      } else {
        const originalValue = copyText.value;
        copyText.value = copiedText;
        copyText.select();
        try {
          document.execCommand('copy');
          alert(this.$t('copied_ip_address') + ' ' + copiedText);
        } catch (err) {
          console.error('Ошибка копирования: ', err);
        } finally {
          copyText.value = originalValue;
        }
      }
    }
  },
}
</script>
