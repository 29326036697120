import axios from 'axios';

const createApiClient = (initData, lang = 'en') => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': initData,
        'TwoFaSession': sessionStorage.getItem('2fa_session_token'),
        'lang': lang
    };

    const params = new URLSearchParams(window.location.search);
    const referral = params.get('startapp');
    if (referral) {
        headers['X-Referral'] = referral;
    }

    console.log(referral);

    const apiClient = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        timeout: 10000,
        headers: headers,
    });

    apiClient.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 403) {
                if (window.location.pathname !== '/two-factor-auth') {
                    window.location.href = '/two-factor-auth';
                }
            }
            return Promise.reject(error);
        }
    );

    return apiClient;
};

export default createApiClient;
