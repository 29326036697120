<template>
  <div class="pt-4 pb-24 px-4">
    <template v-if="loading">
      <div class="rounded-xl">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <h3 class="text-2xl font-bold mb-5">
        {{ exchangeTitle }}
      </h3>
      <dl class="space-y-6 divide-y divide-gray-900/10 border-b pb-4" :class="[$theme.cardBorderColor]">
        <Disclosure as="div" class="" v-slot="{ open }">
          <dt>
            <DisclosureButton class="flex w-full items-start justify-between text-left" :class="[$theme.baseTextColor]">
              <span class="text-base font-semibold leading-7">{{ $t('api_settings') }}</span>
              <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2">
            <div class="mt-4">
              <div class="relative">
                <div class="rounded-lg p-2 mb-2 relative" :class="[$theme.infoAlertBg]">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <InformationCircleIcon class="h-5 w-5" :class="[$theme.infoAlertIconColor]" aria-hidden="true" />
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                      <p class="text-sm" :class="[$theme.infoAlertTextColor]">{{ $t('add_ip_addresses') }}</p>
                    </div>
                  </div>
                </div>
                <div class="relative">
                  <ListIps :list_ips="allowedIPs" :exchange_type="exchange.exchange_slug" />
                </div>
              </div>
              <template v-if="exchange.exchange_slug === 'mexc'">
                <div class="mt-4">
                  <label for="api_key" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">Token</label>
                  <div class="mt-2">
                    <input
                      v-model="form.api_key"
                       type="text"
                       id="api_key"
                       class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                       :class="[
                          $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                      ]"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label for="api_key" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">UID</label>
                  <div class="mt-2">
                    <input
                        v-model="form.uid"
                        type="text"
                        id="api_key"
                        class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                        :class="[
                       $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                    />
                  </div>
                </div>
              </template>
              <template v-if="exchange.exchange_slug === 'binance'">
                <div class="mt-4">
                  <label for="api_key" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">API Key</label>
                  <div class="mt-2">
                    <input
                        v-model="form.api_key" type="text" id="api_key"
                        class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                        :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label for="api_secret" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">API Secret</label>
                  <div class="mt-2">
                    <input
                        v-model="form.api_secret" type="text" id="api_secret"
                        class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                        :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <label for="api_secret" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">
                    {{ $t('api_passphrase') }}
                  </label>
                  <div class="mt-2">
                    <input
                        v-model="form.api_passphrase"
                        type="text"
                        id="api_secret"
                        class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                        :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                    />
                  </div>
                </div>
              </template>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </dl>
      <dl class="space-y-6 divide-y divide-gray-900/10 pt-4 border-b pb-4" :class="[$theme.cardBorderColor]">
        <Disclosure as="div" v-slot="{ open }">
          <dt>
            <DisclosureButton class="flex w-full items-start justify-between text-left" :class="[$theme.baseTextColor]">
              <span class="text-base font-semibold leading-7">{{ $t('trade_settings') }}</span>
              <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2">
            <template v-if="tariff">
              <div class="mt-4">
                <label for="side_mode" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">{{ $t('side_mode') }}</label>
                <div class="mt-2">
                  <select
                      v-model="form.side_mode" id="side_mode"
                      class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                      :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                  >
                    <option value="1">{{ $t('open_long_and_short') }}</option>
                    <option value="2">{{ $t('open_only_long') }}</option>
                    <option value="3">{{ $t('open_only_short') }}</option>
                  </select>
                </div>
              </div>

              <div class="mt-4">
                <label for="initial_margin_type" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">{{ $t('margin_level_type') }}</label>
                <div class="mt-2">
                  <select
                      v-model="form.initial_margin_type"
                      id="initial_margin_type"
                      class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                      :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                      @change="onChangeInitMargin"
                  >
                    <option value="percent">{{ $t('percent') }}</option>
                    <option value="amount">{{ $t('fix_amount') }}</option>
                  </select>
                </div>
              </div>

              <div class="mt-4 relative mb-4">
                <label for="default-range2" class="flex items-center mb-2 text-sm font-medium" :class="[$theme.cardLabelTextColor]">
                  {{ $t('margin_level_open_trade') }}
                  <input
                      v-model="form.initial_margin"
                      type="number" class="block ml-2 w-24 rounded-lg border-0 py-1 shadow-sm ring-1 ring-inset"
                      :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                  />
                </label>
                <input
                    v-model="form.initial_margin"
                    id="default-range"
                    type="range"
                    min="1"
                    :max="form.initial_margin_type === 'percent' ? 100 : tariff.balance_to"
                    step="1"
                    class="w-full h-2 rounded-lg appearance-none cursor-pointer"
                    :class="[$theme.switchBgInactive]"
                    @change="onChangeInitMargin"
                >
                <span class="text-sm absolute start-0 -bottom-6" :class="[$theme.cardLabelTextColor]">
                <template v-if="form.initial_margin_type === 'percent'">
                  Min 1%
                </template>
                <template v-else>
                  Min 1 USD
                </template>
              </span>
                <span class="text-sm absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6" :class="[$theme.cardLabelTextColor]">
                <template v-if="form.initial_margin_type === 'percent'">
                  {{ form.initial_margin }}%
                </template>
                <template v-else>
                  {{ form.initial_margin }} USD
                </template>
              </span>
                <span class="text-sm absolute end-0 -bottom-6" :class="[$theme.cardLabelTextColor]">
                <template v-if="form.initial_margin_type === 'percent'">
                  Max 100%
                </template>
                <template v-else>
                  Max {{ tariff.balance_to }} USD
                </template>
              </span>
              </div>

              <div class="mt-10">
                <label for="max_positions" class="block mb-2 text-sm font-medium" :class="[$theme.cardLabelTextColor]">
                  {{ $t('max_open_positions') }}
                  <div>
                  <span class="inline-flex items-center text-xs font-medium" :class="[$theme.navActiveTextColor2]">
                  {{ $t('allowed_in_tariff_to') }} {{ tariff && tariff.max_positions }}
                </span>
                  </div>
                </label>
                <div class="relative flex items-center max-w-[8rem]">
                  <button
                    @click="onChangeMaxPos('minus')"
                    type="button"
                    class="border rounded-s-lg p-3 h-11 focus:ring-2 focus:outline-none"
                    :class="[$theme.cardBg, $theme.inputBorderColor, $theme.inputRingFocusColor]"
                  >
                    <svg class="w-3 h-3" :class="[$theme.cardLabelTextColor]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16"/>
                    </svg>
                  </button>
                  <input
                      v-model="form.max_positions"
                      type="text"
                      id="max_positions"
                      class="border-x-0 h-11 text-center text-sm block w-full py-2.5"
                      :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                  />
                  <button
                      @click="onChangeMaxPos('plus')"
                      type="button"
                      class="border rounded-e-lg p-3 h-11 focus:ring-2 focus:outline-none"
                      :class="[
                          $theme.cardBg, $theme.inputBorderColor, $theme.inputRingFocusColor,
                          tariff.max_positions <= form.max_positions ? 'pointer-events-none' : ''
                      ]"
                  >
                    <svg
                        class="w-3 h-3 dark:text-white"
                        :class="[
                            tariff.max_positions <= form.max_positions ? 'opacity-50' : '',
                            $theme.cardLabelTextColor
                        ]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 18"
                    >
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"/>
                    </svg>
                  </button>
                </div>
              </div>

              <div class="mt-4">
                <label for="max_value" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">
                  {{ $t('maximum_amount_in_order') }}
                </label>
                <div class="mt-2">
                  <input
                      v-model="form.max_value"
                      type="number"
                      id="max_value"
                      class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                      :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                  />
                </div>
              </div>

              <div class="mt-4 relative mb-4">
                <label for="default-range2" class="flex items-center mb-2 text-sm font-medium" :class="[$theme.cardLabelTextColor]">
                  {{ $t('max_leverage') }}
                  <input
                      v-model="form.leverage"
                      type="number"
                      class="block ml-2 w-24 rounded-md border-0 py-1 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                      :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                  >
                </label>
                <input
                    v-model="form.leverage"
                    id="default-range2"
                    type="range"
                    min="1"
                    max="100"
                    step="1"
                    class="w-full h-2 rounded-lg appearance-none cursor-pointer"
                    :class="[$theme.switchBgInactive]"
                >
                <span class="text-sm absolute start-0 -bottom-6" :class="[$theme.cardLabelTextColor]">Min x1</span>
                <span class="text-sm absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6" :class="[$theme.cardLabelTextColor]">
                x{{ form.leverage }}
              </span>
                <span class="text-sm absolute end-0 -bottom-6" :class="[$theme.cardLabelTextColor]">Max x100</span>
              </div>

              <div class="mt-12">
                <SwitchGroup as="div" class="flex items-center justify-between">
                <span class="flex flex-grow flex-col">
                  <SwitchLabel as="span" class="text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]" passive>{{ $t('status') }}</SwitchLabel>
                  <SwitchDescription as="span" class="text-xs" :class="[$theme.cardLabelTextColor]">
                    {{ $t('if_you_turn_off_open') }}
                  </SwitchDescription>
                </span>
                  <Switch v-model="form.status" :class="[form.status ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2']">
                    <span aria-hidden="true" :class="[form.status ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                  </Switch>
                </SwitchGroup>
              </div>
            </template>
            <template v-else>
              <div class="rounded-xl bg-yellow-100 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <InformationCircleIcon class="h-5 w-5 text-yellow-600" aria-hidden="true" />
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-yellow-700">
                      {{ $t('account_settings_not_available') }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </DisclosurePanel>
        </Disclosure>
      </dl>
      <dl class="space-y-6 divide-y divide-gray-900/10 pt-4 border-b pb-4" :class="[$theme.cardBorderColor]">
        <Disclosure as="div" v-slot="{ open }">
          <dt>
            <DisclosureButton class="flex w-full items-start justify-between text-left" :class="[$theme.baseTextColor]">
              <span class="text-base font-semibold leading-7">ROI</span>
              <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2">
            <div>
              <div class="mt-4">
                <SwitchGroup as="div" class="flex items-center justify-between">
                <span class="flex flex-grow flex-col">
                  <SwitchLabel as="span" class="text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]" passive>
                    {{ $t('close_position_condition') }}
                  </SwitchLabel>
                  <SwitchDescription as="span" class="text-xs" :class="[$theme.cardLabelTextColor]">
                    {{ $t('close_position_condition_desc') }}
                  </SwitchDescription>
                </span>
                  <Switch v-model="form.roi_status" :class="[form.roi_status ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2']">
                    <span aria-hidden="true" :class="[form.roi_status ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                  </Switch>
                </SwitchGroup>
              </div>
              <template v-if="form.roi_status">
                <div class="mt-4">
                  <label for="roi_target" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">
                    {{ $t('conditions_closing') }}
                  </label>
                  <div class="mt-2">
                    <div class="flex items-center justify-between">
                      <div class="w-2/4">
                        <input
                            v-model="form.roi_value"
                            type="number"
                            inputmode="decimal"
                            step="any"
                            id="roi_target"
                            class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                            :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                      ]"
                        />
                      </div>
                      <div class="w-2/4 pl-2">
                        <select
                            v-model="form.roi_type"
                            id="roi_type"
                            class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                            :class="[
                        $theme.baseTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                        >
                          <option value="pnl_value">PnL Amount</option>
                          <option value="pnl_percent">PnL %</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="text-xs mt-1.5">
                    <template v-if="form.roi_type === 'pnl_percent'">
                      <span :class="[$theme.cardLabelTextColor]">{{ $t('cond_closing_note') }}</span> <span :class="[$theme.successPnlColor]">+{{ form.roi_value }}% PnL</span>
                    </template>
                    <template v-if="form.roi_type === 'pnl_value'">
                      <span :class="[$theme.cardLabelTextColor]">{{ $t('cond_closing_note') }}</span> <span :class="[$theme.successPnlColor]">+{{ form.roi_value }} USDT</span>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </dl>
      <div>
        <div class="mt-5">
          <button
              @click="updateExchange"
              type="button"
              class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
              :class="[formProcessing ? 'opacity-50 pointer-events-none' : '', $theme.inverseBaseBg]"
          >
            <template v-if="formProcessing">
              <ButtonSpinner />
            </template>
            {{ $t('update_settings') }}
          </button>
          <div class="mt-2 text-xs text-center" :class="[$theme.cardLabelTextColor]">
            {{ $t('note_update_settings') }}
          </div>
        </div>
        <template v-if="formError.errors">
          <div class="rounded-md bg-red-50 p-4 mt-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">{{ formError.message }}</h3>
                <div class="mt-2 text-sm text-red-700">
                  <ul role="list" class="list-disc space-y-1 pl-5">
                    <li v-for="(er, k) in formError.errors" :key="k">{{ er[0] }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="mt-5">
          <button
              @click="openConfirmRemovePopup = true"
              type="button"
              class="flex w-full items-center justify-center rounded-xl bg-red-50 px-3 py-3 font-semibold text-red-600 hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-50"
          >
            {{ $t('delete_account') }}
          </button>
        </div>
      </div>
    </template>
  </div>

  <TransitionRoot as="template" :show="openConfirmRemovePopup">
    <Dialog class="relative z-50" @close="openConfirmRemovePopup = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-950 bg-opacity-45 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-t-xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full">
              <div>
                <div class="flex items-center justify-center">
                  <img src="/img/undraw_throw_away_re_x60k.svg" class="w-36">
                </div>
                <div class="flex items-center justify-center text-xl font-semibold mt-5 mb-3">
                  {{ $t('confirm_account_deletion') }}
                </div>
                <div class="flex items-center justify-center text-center text-sm">
                  {{ $t('confirm_account_deletion_note') }}
                </div>
                <div class="mt-5">
                  <button
                      @click="removeExchange"
                      type="button"
                      class="flex w-full items-center justify-center rounded-xl bg-red-50 px-3 py-3 font-semibold text-red-600 hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-50"
                      :class="[formRemoveProcessing ? 'opacity-50 pointer-events-none' : '']"
                  >
                    <template v-if="formRemoveProcessing">
                      <ButtonSpinner text_color="text-red-600" />
                    </template>
                    {{ $t('okay_delete_account') }}
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import LoadingBlock from '@/components/LoadingBlock.vue'
import ButtonSpinner from '@/components/ButtonSpinner.vue'
import {
  InformationCircleIcon,
  XCircleIcon
} from '@heroicons/vue/20/solid'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'
import { initFlowbite } from 'flowbite'
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";
import {capitalizeFirstLetter} from "@/helpers/stringHelper"
import ListIps from "@/components/elem/ListIps.vue";

export default {
  components: {
    ListIps,
    LoadingBlock,
    ButtonSpinner,
    InformationCircleIcon,
    XCircleIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MinusSmallIcon,
    PlusSmallIcon,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },

  computed: {
    exchangeTitle() {
      if (!this.exchange) {
        return '';
      }

      return capitalizeFirstLetter(this.exchange.exchange_slug);
    },

    tariff() {
      if (!this.$user) {
        return null;
      }

      if (!this.$user.tariff) {
        return null;
      }

      return this.$user.tariff.tariff;
    }
  },

  methods: {
    removeExchange() {
      this.formRemoveProcessing = true;

      const id = this.$route.params.id;

      this.$api.delete(`/exchanges/${id}`).then((result) => {
        if (result.status === 204) {
          this.$router.push({ name: 'setting' });
        }
      }).catch(e => {
        console.log(e.response.data)
        this.formRemoveProcessing = false;
      })
    },

    updateExchange() {
      this.formProcessing = true;

      const id = this.$route.params.id;

      this.$api.post(`/exchanges/${id}`, this.form).then((result) => {
        console.log(result.data)

        if (result.data.status === 1) {
          this.$router.push({
            name: 'setting'
          })
        } else {
          this.formError.message = 'Error connect'
          this.formError.errors = {
            error_connect: [result.data.message]
          }
          this.formProcessing = false;
        }
      }).catch(e => {
        console.log(e.response.data)
        this.formError = e.response.data;
        this.formProcessing = false;
      })
    },

    loadExchange() {
      const id = this.$route.params.id;

      this.$api.get(`/exchanges/${id}`).then((result) => {
        console.log(result.data.data);
        this.exchange = result.data.data

        this.form.type_exchange = this.exchange.exchange_slug;
        this.form.api_key = this.exchange.api_key;
        this.form.api_secret = this.exchange.api_secret;
        this.form.api_passphrase = this.exchange.api_passphrase;
        this.form.initial_margin = this.exchange.initial_margin;
        this.form.initial_margin_type = this.exchange.initial_margin_type;
        this.form.side_mode = this.exchange.side_mode;
        this.form.max_positions = this.exchange.max_positions;
        this.form.leverage = this.exchange.leverage;
        this.form.max_value = this.exchange.max_value;
        this.form.status = this.exchange.status;
        this.form.uid = this.exchange.mexc_uid;
        this.form.roi_status = this.exchange.roi_status;
        this.form.roi_type = this.exchange.roi_type;
        this.form.roi_value = this.exchange.roi_value;

        this.loading = false;
      })
    },

    onChangeMaxPos(type) {
      if (type === 'plus' && this.tariff.max_positions > this.form.max_positions) {
        this.form.max_positions = parseInt(this.form.max_positions) + 1;
      }

      if (type === 'minus' && this.form.max_positions >= 2) {
        this.form.max_positions = parseInt(this.form.max_positions) - 1;
      }
    },

    onChangeInitMargin() {
      if (this.form.initial_margin_type === 'percent' && this.form.initial_margin > 100) {
        this.form.initial_margin = 100;
      }
    },

    copyToClipboard() {
      const copyText = this.$refs.ipAddressInput;
      copyText.select();
      document.execCommand('copy');
      alert("Copied the IP address: " + this.allowedIPs);
    }
  },

  data() {
    return {
      loading: true,
      error: null,
      selectedExchange: null,
      allowedIPs: this.$settings.list_ips,
      exchange: null,
      formError: {
        message: '',
        errors: null,
        status: 0,
      },
      form: {
        type_exchange: null,
        api_key: '',
        api_secret: '',
        api_passphrase: '',
        uid: '',
        initial_margin_type: '',
        initial_margin: 1,
        side_mode: 0,
        max_positions: 0,
        max_value: 0.00,
        leverage: 1,
        status: false,
        roi_status: false,
        roi_type: 'pnl_percent',
        roi_value: 2.00,
      },
      openConfirmRemovePopup: false,
      formProcessing: false,
      formRemoveProcessing: false,
      capitalizeFirstLetter
    }
  },

  mounted() {
    initFlowbite();
    this.loadExchange();
    addBackButton(this.$router);

    console.log('tariff', this.$user.tariff.tariff);
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>