<template>
  <div class="pt-4 pb-20 px-4">
    <h3 class="text-2xl font-bold mb-5">
      {{ $t('add_exchange_account') }}
    </h3>
    <template v-if="loading">
      <div class="rounded-xl" :class="[$theme.cardBg]">
        <LoadingBlock />
      </div>
    </template>
    <template v-else>
      <div>
        <Listbox as="div" v-model="selectedExchange">
          <ListboxLabel class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">
            {{ $t('exchange') }}
          </ListboxLabel>
          <div class="relative mt-2">
            <ListboxButton
                class="relative w-full cursor-default rounded-lg py-2 pl-3.5 pr-10 text-left shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2"
                :class="[
                  $theme.cardLabelTextColor,
                  $theme.inputPlaceTextColor,
                  $theme.inputRingFocusColor,
                  $theme.inputBorderColor,
                  $theme.inputRingColor,
                  $theme.cardBg,
                ]"
            >
              <div class="flex items-center">
                <span v-if="selectedExchange"  class="flex items-center">
                  <img :src="`/img/${selectedExchange.slug}.png`" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                  <span class="ml-3 block truncate">{{ selectedExchange.name }}</span>
                </span>
                <span v-else class="ml-3 block truncate">
                  {{ $t('select_exchange') }}
                </span>
              </div>
              <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon class="h-5 w-5" :class="[$theme.cardLabelTextColor]" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
              <ListboxOptions class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <ListboxOption
                  as="template"
                  v-for="exchange in exchanges"
                  :key="exchange.id"
                  :value="exchange"
                  :disabled="exchange.status === 'coming_soon'"
                  v-slot="{ active }"
                >
                  <li :class="[
                    exchange.status === 'coming_soon' ? 'opacity-50' : '',
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    !isMexc && exchange.slug === 'mexc' ? 'hidden' : ''
                  ]">
                    <div class="flex items-center">
                      <img :src="`/img/${exchange.slug}.png`" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                      <span :class="[active ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">
                        {{ exchange.name }}
                      </span>
                      <span v-if="exchange.status === 'coming_soon'" :class="[
                          'ml-3 text-white inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
                          $theme.bgDangerColor,
                      ]">{{ $t('coming_soon') }}</span>
                    </div>
                    <span v-if="selectedExchange && selectedExchange.slug === exchange.slug" :class="[$theme.navActiveTextColor2, 'absolute inset-y-0 right-0 flex items-center pr-4']">
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
        <div class="mt-4">
          <div class="relative">
            <div class="rounded-lg p-2 mb-2 relative" :class="[$theme.infoAlertBg]">
              <div class="flex">
                <div class="flex-shrink-0">
                  <InformationCircleIcon class="h-5 w-5" :class="[$theme.infoAlertIconColor]" aria-hidden="true" />
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                  <p class="text-sm" :class="[$theme.infoAlertTextColor]">{{ $t('add_ip_addresses') }}</p>
                </div>
              </div>
            </div>
            <div v-if="selectedExchange" class="relative">
              <ListIps :list_ips="allowedIPs" :exchange_type="selectedExchange.slug" />
            </div>
          </div>
          <template v-if="selectedExchange && selectedExchange.slug === 'mexc'">
            <div class="mt-4">
              <label for="api_key" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">Token</label>
              <div class="mt-2">
                <input
                    v-model="form.api_key"
                    type="text"
                    id="api_key"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.cardLabelTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
            <div class="mt-4">
              <label for="api_secret" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">UID</label>
              <div class="mt-2">
                <input
                    v-model="form.uid"
                    type="text"
                    id="api_secret"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.cardLabelTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
          </template>
          <template v-if="selectedExchange && selectedExchange.slug === 'binance'">
            <div class="mt-4">
              <label for="api_key" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">API Key</label>
              <div class="mt-2">
                <input
                    v-model="form.api_key"
                    type="text"
                    id="api_key"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.cardLabelTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
            <div class="mt-4">
              <label for="api_secret" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">API Secret</label>
              <div class="mt-2">
                <input
                    v-model="form.api_secret"
                    type="text" id="api_secret"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.cardLabelTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
            <div class="mt-4">
              <label for="api_secret" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">
                {{ $t('api_passphrase') }}
              </label>
              <div class="mt-2">
                <input
                    v-model="form.api_passphrase"
                    type="text"
                    id="api_secret"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.cardLabelTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
          </template>
          <template v-if="selectedExchange && selectedExchange.slug === 'whitebit'">
            <div class="mt-4">
              <label for="api_key" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">API Key</label>
              <div class="mt-2">
                <input
                    v-model="form.api_key"
                    type="text"
                    id="api_key"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.cardLabelTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
            <div class="mt-4">
              <label for="api_secret" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">API Secret</label>
              <div class="mt-2">
                <input
                    v-model="form.api_secret"
                    type="text" id="api_secret"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.cardLabelTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
          </template>
          <template v-if="selectedExchange && selectedExchange.slug === 'bybit'">
            <div class="mt-4">
              <label for="api_key" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">API Key</label>
              <div class="mt-2">
                <input
                    v-model="form.api_key"
                    type="text"
                    id="api_key"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.cardLabelTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
            <div class="mt-4">
              <label for="api_secret" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">API Secret</label>
              <div class="mt-2">
                <input
                    v-model="form.api_secret"
                    type="text" id="api_secret"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.cardLabelTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
            <div class="mt-4">
              <label for="api_secret" class="block text-sm font-medium leading-6" :class="[$theme.cardLabelTextColor]">
                {{ $t('api_passphrase') }}
              </label>
              <div class="mt-2">
                <input
                    v-model="form.api_passphrase"
                    type="text"
                    id="api_secret"
                    class="block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset"
                    :class="[
                        $theme.cardLabelTextColor,
                        $theme.inputPlaceTextColor,
                        $theme.inputRingFocusColor,
                        $theme.inputBorderColor,
                        $theme.inputRingColor,
                        $theme.cardBg,
                    ]"
                />
              </div>
            </div>
          </template>
          <div class="mt-4">
            <button
                @click="connectExchangeAccount"
                type="button"
                class="flex w-full items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
                :class="[formProcessing ? 'opacity-50 pointer-events-none' : '', $theme.inverseBaseBg]"
            >
              <template v-if="formProcessing">
                <ButtonSpinner />
              </template>
              {{ $t('connect_exchange') }}
            </button>
          </div>
        </div>
        <template v-if="formError.errors">
          <div class="rounded-md bg-red-50 p-4 mt-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">{{ formError.message }}</h3>
                <div class="mt-2 text-sm text-red-700">
                  <ul role="list" class="list-disc space-y-1 pl-5">
                    <li v-for="(er, k) in formError.errors" :key="k">{{ er[0] }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingBlock from '@/components/LoadingBlock.vue'
import ButtonSpinner from '@/components/ButtonSpinner.vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import {
  CheckIcon,
  ChevronUpDownIcon,
  InformationCircleIcon,
  XCircleIcon
} from '@heroicons/vue/20/solid'
import {addBackButton, hideBackButton} from "@/helpers/telegramHelper";
import ListIps from "@/components/elem/ListIps.vue";

export default {
  components: {
    LoadingBlock,
    ButtonSpinner,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
    InformationCircleIcon,
    XCircleIcon,
    ListIps
  },

  computed: {
    isMexc() {
      return this.$user?.tariff?.tariff?.exchanges && this.$user.tariff.tariff.exchanges.indexOf('mexc') !== -1;
    },
  },

  methods: {
    connectExchangeAccount() {
      this.formProcessing = true;

      if (this.selectedExchange) {
        this.form.type_exchange = this.selectedExchange.slug;
      }

      this.$api.post('/exchanges/new', this.form).then((result) => {
        if (result.data.status === 1) {
          this.$router.push({
            name: 'edit-exchange',
            params: {
              id: result.data.data.id
            }
          })
        } else {
          this.formError.message = 'Error connect'
          this.formError.errors = {
            error_connect: [result.data.message]
          }
          this.formProcessing = false;
        }
      }).catch(e => {
        console.log(e.response.data)
        this.formError = e.response.data;
        this.formProcessing = false;
      })
    },

    copyToClipboard() {
      const copyText = this.$refs.ipAddressInput;
      copyText.select();
      document.execCommand('copy');
      alert(this.$t('copied_ip_address') + ' ' + this.allowedIPs);
    }
  },

  data() {
    return {
      loading: true,
      error: null,
      selectedExchange: null,
      allowedIPs: this.$settings.list_ips,
      exchanges: [
        {
          id: 1,
          name: 'Binance',
          slug: 'binance',
          status: 'active',
        },
        {
          id: 2,
          name: 'Mexc',
          slug: 'mexc',
          status: 'active',
        },
        {
          id: 3,
          name: 'Whitebit',
          slug: 'whitebit',
          status: 'active',
        },
        {
          id: 4,
          name: 'Bybit',
          slug: 'bybit',
          status: 'active',
        },
      ],
      formError: {
        message: '',
        errors: null,
        status: 0,
      },
      form: {
        type_exchange: null,
        api_key: '',
        api_secret: '',
        api_passphrase: '',
        uid: '',
      },
      formProcessing: false,
    }
  },

  mounted() {
    this.loading = false
    addBackButton(this.$router);

    if (this.$settings.theme === 'bworld') {
      this.exchanges.push({
        id: 5,
        name: 'OKX',
        slug: 'okx',
        status: 'coming_soon',
      });
      this.exchanges.push({
        id: 6,
        name: 'Bitget',
        slug: 'bitget',
        status: 'coming_soon',
      });
      this.exchanges.push({
        id: 7,
        name: 'Coinbase',
        slug: 'coinbase',
        status: 'coming_soon',
      });
      this.exchanges.push({
        id: 8,
        name: 'Kucoin',
        slug: 'kucoin',
        status: 'coming_soon',
      });
      this.exchanges.push({
        id: 9,
        name: 'gate.io',
        slug: 'gate_io',
        status: 'coming_soon',
      });
      this.exchanges.push({
        id: 10,
        name: 'Kraken',
        slug: 'kraken',
        status: 'coming_soon',
      });
      this.exchanges.push({
        id: 11,
        name: 'Bitmart',
        slug: 'bitmart',
        status: 'coming_soon',
      });
    }
  },

  beforeUnmount() {
    hideBackButton();
  }
}
</script>